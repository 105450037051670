import React from 'react'

const Gujarat = () => {
  return (
    <>
      <section>
      <img src="../assets/img/gujrat-sub.jpg" class="w-100 height-215" alt="" />
    </section>
    <section class="mt-5 animation-element slide-up testimonial in-view">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 maha mb-2">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <b>Ankleshwar</b>
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Door To Door Refuse Garbage Collection And Transportation Upto Dumping Site / Treatment Plant Sukawali/ Identified Site By Ankleshwar Nagarpalika Using Close Body Vehicles With Tipping Arrangement Including Labours In Accordance With Solid Waste Management Rules – 2016 For The Period Of Five Years For Ankleshwar Municipal Area
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  <b>Anjar</b>
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                    <li class="list-group-item">
                          <i class="fa fa-check"></i> Providing services of Sweeping/ Cleaning (including night sweeping) to all wards (1to9) Anjar Nagar Palika area.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <b>Dakor</b>
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Door To Door Collection Of Solid Waste, Segregation From All Properties In Dakor Nagarpalika Area.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    <b>Bhuj</b>
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Complete cleaning of commercial, industrial, residential, government offices and all garbage heaps and containers, dustbins, unnecessary paving stones, bush cuttings, earthworks, door to door waste collection in Zone-2, Zone-3 and Zone 4 of Bhuj Municipal Area. And Operation of conveyance to dumping site
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    <b>Khambhat</b>
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Door to door collection of garbage Collection with Close body garbage tipper vehicle including Fuel and necessary and sufficient at manpower for Khambhat Nagarpalika.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    <b>Anand</b>
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Selection of Contractor for Door-to-Door Collection & Transportation and Transportation work through Latest Technology, Systems of ANAND NAGARPALIKA.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
             
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                    <b>Borsad</b>
                  </button>
                </h2>
                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Selection of Contractor for Road/Street Sweeping Work at Borsad Nagarpalika.  
                        And 
                        RFP for Selection of Contractor for Door-to-Door Collection through Latest Technology & System in jurisdiction of Borsad Nagarpalika

                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingNine">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                    <b>Dhandhuka </b>
                  </button>
                </h2>
                <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Door-to-Door/Gate MSW collection and transportation ULBs/own closed body vehicles, with tipping arrangement including labors for a concession period of 1 years in accordance with the solid waste management rules of 2016, in Dhandhuka ULB
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                    <b>Dwarka </b>
                  </button>
                </h2>
                <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Door-to-Door/Gate including GVP’s or open garbage point MSW collection and transportation Own closed body vehicles, with tipping arrangement including labors for a concession period of 2 years in accordance with the solid waste management rules of 2016, in Dwarka Nagarpalika. 
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 display-none">
            <img src="../assets/img/new-img-2.png" class="w-75 width-25 margin-auto d-flex" alt="" />
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Gujarat
