import React ,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const FrontFooter = () => {
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

useEffect(() => {
    const mybutton = document.getElementById("myBtn");

    // When the user scrolls down 20px from the top of the document, show the button
    const scrollFunction = () => {
        if (mybutton) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
    };

    window.onscroll = function () {
        scrollFunction();
    };

    // Clean up the event listener when the component unmounts
    return () => {
        window.onscroll = null;
    };
}, []); // Empty dependency array to run the effect only once on mountconst [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <footer className="footer-bg mt-4 p-xl-5 p-lg-5 p-md-3 p-sm-2 p-2">
        <div className="container bg_contai_footer pt-4  pb-3">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
              <div className="row d-flex justify-content-start  align-items-center">
                <div className="col-xl-4 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 d-flex justify-content-center">
                  <img src="../assets/img/logo.png" className="login_icon w-50" />
                </div>
                <div className="col-xl-7 col-lg-9 col-md-8 col-sm-12 col-12">
                  <h5 className="white-color mobile-center">“READY TO OPTIMIZE YOUR WASTE MANAGEMENT
                    ”</h5>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-3 ">
              <div
                className="d-flex justify-content-xl-end justify-content-lg-end  justify-content-md-center justify-content-sm-center  justify-content-center align-items-end">
                <a href='/ContactUs' className="btn text-decoration-none contact_us">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
              <div className="mt-4">
                <img src="../assets/img/logo.png" width="30%" />
                <p className="text-white mt-xl-3 mt-lg-3 mt-md-1 mt-sm-1 mt-1 para-text">We recognize that the right service for
                  home is the most important chosie, wastia can provide the waste collection you need for your home, with
                  trusted service.</p>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3 ">
              <h4 className="text-white mt-xl-4 mt-lg-4 mt-md-2 mt-sm-1 mt-1">Company</h4>
              <div className="row justify-content-center mt-3 footer-hover">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <p className="mb-2 link-font para-text">
                    <span><a href="/About-us" className="ContactAddress text-white text-decoration-none">About Us</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/OngoingProjects" className="ContactAddress text-white text-decoration-none">Projects</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Services" className="ContactAddress text-white text-decoration-none">Services</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span className="ContactAddress text-white"><a href="/NewsMedia" className="ContactAddress text-white text-decoration-none">News & Media</a></span>
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Gallery" className="ContactAddress text-white text-decoration-none">Gallery</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Career" className="ContactAddress text-white text-decoration-none">Career</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/ContactUs" className="ContactAddress text-white text-decoration-none">Contact Us</a></span>
                  </p>

                </div>

              </div>


            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3 footer-hover">
              <h4 className="text-white mt-xl-4 mt-lg-4 mt-md-2 mt-sm-1 mt-1">Services</h4>
              <div className="row justify-content-center mt-3">

                <div className="col-12">
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Services" className="ContactAddress text-white text-decoration-none">Door To Door</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Services" className="ContactAddress text-white text-decoration-none">Road Sweeping/Cleaning</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Services" className="ContactAddress text-white text-decoration-none">Segregation</a></span>
                  </p>
                  <p className="mb-2 link-font para-text">
                    <span><a href="/Services" className="ContactAddress text-white text-decoration-none">Transporation</a></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <h4 className="text-white "> Quick Contact</h4>
              <div className="row justify-content-center">

                <div className="col-12">
                  <div className="row justify-content-center  mt-3">
                    <div className="col-2 ">
                      <i className="fas fa-map-marker-alt address-icon"></i>
                    </div>
                    <div className="col-10">
                      <p className="mb-0 link-font">
                        <span className="ContactNo1 text-white">26, Matey Square, BPCL Petrol Pump,Gopal Nagar Road, Nagpur 440022</span>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <a href="tel:+91-712 299 6029" className='text-decoration-none row justify-content-center  mt-3'>
                    <div className="col-2 ">
                      <i className="fas fa-phone-square-alt address-icon"></i>
                    </div>
                    <div className="col-10">
                      <p className="mb-0 link-font">
                        <span className="ContactNo1 text-white">+91-712 299 6029</span>
                      </p>
                    </div>
                    </a>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </footer>
      <section>
        <div className="container-fluid">
          <i onClick={topFunction} id="myBtn" className="fas fa-arrow-up blob"></i>
        </div>
      </section>
    </>
  )
}

export default FrontFooter
